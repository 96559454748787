<template>
  <div>
    <v-btn
      class="text-none"
      :color="color"
      :depressed="depressed"
      :outlined="outlined"
      :dense="dense"
      :loading="loading"
      :disabled="disabled"
      @click="onButtonClick"
    >
      <v-icon left> mdi-cloud-upload </v-icon>
      {{ label }}
    </v-btn>
    <input
      v-show="false"
      ref="upload"
      type="file"
      :accept="accept"
      :multiple="multiple"
      @change="onFileChanged"
    />
  </div>
</template>

<script>
export default {
  name: "ButtonUpload",
  props: {
    depressed: Boolean,
    outlined: Boolean,
    dense: Boolean,
    disabled: Boolean,
    rule: [Boolean, Array],
    loading: Boolean,
    label: {
      type: String,
      default: "Upload",
    },
    color: {
      type: String,
      default: "primary",
    },
    accept: String,
    multiple: Boolean,
    reset: Boolean,
  },
  watch: {
    reset(newValue) {
      if (newValue) {
        this.$refs.upload.value = "";
      }
    },
  },
  data() {
    return {
      document: null,
    };
  },
  methods: {
    onButtonClick() {
      this.$refs.upload.click();
    },
    onFileChanged(e) {
      const files = [...e.target.files];
      if (this.multiple) {
        this.$emit("getDocument", files);
      } else {
        this.$emit("getDocument", files[0]);
      }
    },
  },
};
</script>
